import React from "react";
import { ButtonContainer } from "./styles";

const Button = ({
  name,
  type = "button",
  onClick = () => {},
  style,
  disabled = false
}) => {
  return (
    <React.Fragment>
      <ButtonContainer
        type={type}
        onClick={onClick}
        style={style}
        disabled={disabled}
      >
        {name}
      </ButtonContainer>
    </React.Fragment>
  );
};

export default Button;
