import { Formik } from "formik";
import React from "react";
import * as yup from "yup";
import Button from "../../../components/Presentational/Buttons/Secondary";
import Input from "../../../components/Presentational/Input/Password";

const styles = {
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 30
  }
};

const schema = yup.object({
  password: yup
    .string()
    .matches(/\w*[a-z]\w*/, "A senha deve ter uma letra minúscula")
    .matches(/\w*[A-Z]\w*/, "A senha deve ter uma letra maiúscula")
    .matches(/\d/, "A senha deve ter um número")
    .matches(
      /[!@#$%^&*()\-_"=+{}; :,<.>]/,
      "A senha deve ter um caractere especial"
    )
    .min(
      8,
      ({ min }) =>
        `Mínimo de ${min} caracteres. Necessário letras maiúsculas e minúsculas, além de números`
    )
    .required("Informe sua senha"),
  newPassword: yup
    .string()
    .oneOf([yup.ref("password")], "As senhas não coincidem")
    .required("Confirme sua senha")
});

const FormPassword = ({ onSubmit }) => {
  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{ password: "", newPassword: "" }}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors }) =>
        <form onSubmit={handleSubmit}>
          <Input
            type="password"
            placeholder="Crie sua senha"
            name="password"
            value={values.password}
            required
            onChange={handleChange}
          />
          <small>
            {errors.password}
          </small>
          <Input
            type="password"
            placeholder="Confirme sua senha"
            name="newPassword"
            value={values.newPassword}
            required
            onChange={handleChange}
          />
          <small>
            {errors.newPassword}
          </small>

          <div style={styles.button}>
            <Button name="Criar senha" type="submit" />
          </div>
        </form>}
    </Formik>
  );
};

export default FormPassword;
