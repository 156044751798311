import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Home from "./pages/Home";
import AwardMP from "./pages/AwardMP";
import ActionEmail from "./pages/ActionEmail";
import Way from "./pages/Way";

export default () =>
  <BrowserRouter basename="/">
    <Switch>
      <Route path="/" exact={true} component={() => <div />} />
      <Route path="/coupons" exact={true} component={Home} />
      <Route
        path="/premio-mulheres-posiitivas"
        exact={true}
        component={AwardMP}
      />
      <Route path="/acao/email" exact={true} component={ActionEmail} />
      <Route path="/caminho-delas" exact={true} component={Way} />
      <Route path="*" component={() => <Redirect to="/" />} />
    </Switch>
  </BrowserRouter>;
