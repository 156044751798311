import React from "react";
import IMAGE1 from "./images/premio-mulheres-posiitivas.jpg";

const AwardMP = () => {
  return (
    <div
      style={{
        alignItems: "center",
        backgroundColor: "hsla(0, 0%, 98%, 1)",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        margin: "0 auto",
        maxWidth: 1000,
        width: "100vw",
        padding: "0 30px",
      }}
    >
      <section
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <nav>
          <img
            src={IMAGE1}
            alt="Premio Mulheres Positivas"
            style={{
              borderRadius: 6,
              maxWidth: "100%",
            }}
          />
        </nav>
      </section>
    </div>
  );
};

export default AwardMP;
