import styled from "styled-components";

export const Container = styled.div`
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid rgb(218, 218, 218);
  margin-bottom: 10px;

  input {
    border: 0;

    width: 100%;
    outline: none;
    padding: 0.75rem;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    ::placeholder {
      color: #b7b8bf;
    }
  }

  i {
    padding: 15px;
    cursor: pointer;
  }
`;
