import styled from "styled-components";
import colors from "../../../../styles/colors";

export const ButtonContainer = styled.button`
  border: 1px solid ${colors.disabled};
  color: ${colors.white};
  margin-right: 0.5rem;
  text-align: center;
  cursor: pointer;
  background-color: ${colors.disabled};
  border-radius: 8px;
  padding: 12px 16px 12px 16px;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.75px;

  :disabled {
    background-color: ${colors.disabled};
    border: 1px solid ${colors.disabled};
  }

  :hover {
    background-color: ${colors.primary};
    border: 1px solid ${colors.primary};
  }
`;
