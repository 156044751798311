import React, { useState } from "react";
import { logAnalyticsEvent } from "../../../../services/Analytics";
import IMAGE4 from "./images/image4.gif";

const styles = {
  card: {
    marginBottom: 10
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    margin: 10
  },
  circle: {
    height: 50,
    width: 50,
    backgroundColor: "#F9F9F9",
    borderRadius: "50%",
    display: "inline-block",
    marginTop: 40
  },
  icon: {
    marginLeft: 5,
    marginTop: 10,
    cursor: "pointer"
  },
  paragraph: {
    fontWeight: "bold",
    fontSize: 14,
    marginTop: 10
  }
};

const Coupons = ({ code, partner, description, onClose }) => {
  const [textCopy, setTextCopy] = useState("Copiar código");

  return (
    <div className="card" style={styles.card}>
      <div style={styles.container}>
        <i
          className="fa fa-close"
          style={{ cursor: "pointer" }}
          onClick={() => onClose()}
        />
      </div>
      <img src={IMAGE4} className="card-img-top" alt="" />
      <div className="card-body text-center">
        <h3 className="card-title text-center">
          {code}
        </h3>
        <h4 className="card-title text-center">
          {description} - {partner}
        </h4>
        <span style={styles.circle}>
          <i
            className="fa fa-link fa-2x"
            style={styles.icon}
            onClick={() => {
              logAnalyticsEvent("resgatar_cupom", {
                screen_name: "cupom_resgate",
                code: code
              });

              setTextCopy("Copiado com sucesso!");
              navigator.clipboard.writeText(code);
              setTimeout(() => {
                setTextCopy("Copiar código");
              }, 1000);
            }}
          />
        </span>
        <p style={styles.paragraph}>
          {textCopy}
        </p>
      </div>
    </div>
  );
};

export default Coupons;
