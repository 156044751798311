import React, { useState } from "react";
import { logAnalyticsEvent } from "../../../../services/Analytics";

import Coupons from "./Coupons";
import IMAGE6 from "./images/image6.png";

const styles = {
  list: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    cursor: "pointer"
  },
  circle: {
    height: 50,
    width: 50,
    borderRadius: "50%",
    display: "inline-block",
    objectFit: "contain"
  },
  titleList: {
    fontSize: 14,
    fontWeight: 600,
    textAlign: "left",
    marginBottom: 0,
    marginLeft: 15
  },
  icon: {
    marginTop: 10,
    cursor: "pointer"
  },
  button: {
    textAlign: "center",
    fontWeight: 600,
    cursor: "pointer",
    marginTop: 20
  }
};

const ListCoupons = ({ coupons }) => {
  const [limit, setLimit] = useState(3);
  const [button, setButton] = useState(true);
  const [display, setDisplay] = useState("");

  const listCoupom = rows => {
    return rows.slice(0, limit).map((data, i) => {
      return (
        <React.Fragment key={i}>
          {i === display
            ? <Coupons
                code={data.code}
                partner={data.partner}
                description={data.description}
                onClose={() => setDisplay("")}
              />
            : <li
                key={i}
                className="list-group-item"
                style={styles.list}
                onClick={() => {
                  logAnalyticsEvent("ver_cupom", {
                    screen_name: "cupom"
                  });
                  setDisplay(i);
                }}
              >
                <img
                  src={data.imageUrl}
                  alt=""
                  width="40"
                  height="40"
                  style={styles.circle}
                />
                <p style={styles.titleList}>
                  {data.title}
                </p>
                <img
                  src={IMAGE6}
                  alt=""
                  width={25}
                  height={20}
                  style={styles.icon}
                />
              </li>}
        </React.Fragment>
      );
    });
  };

  return (
    <React.Fragment>
      <ul className="list-group">
        {listCoupom(coupons)}
      </ul>
      {button &&
        coupons.length > 3 &&
        <p
          style={styles.button}
          onClick={() => {
            setLimit(coupons.length);
            setButton(false);
          }}
        >
          Ver todos os cupons
        </p>}
    </React.Fragment>
  );
};

export default ListCoupons;
