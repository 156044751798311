const MESSAGES = {
  "Firebase: Error (auth/user-not-found).":
    "Não encontramos uma conta vinculada a este email.",
  "Firebase: Error (auth/wrong-password).":
    "Sua senha está errada, por favor tente novamente.",
  "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).":
    "Máximo de tentativas excedido. Tente novamente mais tarde.",
  "Firebase: Error (auth/invalid-action-code).":
    "Sua solicitação para redefinir sua senha expirou ou o link já foi usado, tente redefinir sua senha novamente."
};

export function translateMessage(message) {
  return (
    MESSAGES[message] || `Ops, ocorreu um erro, tente novamente mais tarde.`
  );
}
