import React, { useState, useEffect } from "react";
import Loader from "react-fullscreen-loading";
import {
  getRemoteConfig,
  fetchAndActivate,
  getValue,
} from "firebase/remote-config";
import ListCoupon from "./partials/ListCoupons";
import IMAGE2 from "./partials/images/image2.png";
import IMAGE5 from "./partials/images/image5.png";

const styles = {
  main: {
    width: "100%",
    maxWidth: 400,
    margin: "auto",
    paddingTop: 50,
  },
  section: {
    margin: 20,
  },
  description: {
    textAlign: "left",
    marginTop: 20,
  },
  paragraph: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: 600,
  },
  notFound: {
    marginTop: 30,
    textAlign: "center",
    fontSize: 14,
    fontWeight: 600,
  },
};

const CouponsContainers = () => {
  const [loader, setLoader] = useState(true);
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    async function teste() {
      try {
        const remoteConfig = getRemoteConfig();
        remoteConfig.settings = {
          fetchTimeMillis: 60000,
          minimumFetchIntervalMillis: 1,
        };
        await fetchAndActivate(remoteConfig);
        const response = getValue(remoteConfig, "coupons");
        setCoupons(JSON.parse(response._value));
        setLoader(false);
      } catch (error) {
        setCoupons([]);
        setLoader(false);
      }
    }

    teste();
  }, []);

  return (
    <main style={styles.main}>
      <section style={styles.section}>
        <img src={IMAGE2} alt="" />
        <h1 className="h3 mb-3" style={styles.description}>
          Pronta para aproveitar o <br /> nosso clube de benefícios?
        </h1>
        <p style={styles.paragraph}>
          <img src={IMAGE5} alt="" width={30} /> A seguir, resgate seus cupons.
        </p>
        {loader && (
          <Loader loading background="#f5f5f5" loaderColor="#266A69" />
        )}
        {coupons.length > 0 ? (
          <ListCoupon coupons={coupons} />
        ) : (
          <p style={styles.notFound}>
            Ops, não temos cupons disponiveis no momento. Tente novamente mais
            tarde!
          </p>
        )}
      </section>
    </main>
  );
};

export default CouponsContainers;
