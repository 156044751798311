import React from "react";

const Way = () => {

  return <iframe src="https://webview-caminho-delas.web.app" title="Iframe" style={{
    display: 'block',
    border: 'none',
    height: '100vh',
    width: '100vw'
  }}></iframe>
};

export default Way;
