import React, { useState, useEffect } from "react";
import queryString from "query-string";
import LOGO from "./images/logo.png";
import Loader from "react-fullscreen-loading";
import FormPassword from "./partials/FormPassword";
import { sendResetPassword } from "../../services/Firebase";
import { toastrMessage } from "../../utils/Toastr";

const styles = {
  main: {
    width: "100%",
    maxWidth: 400,
    margin: "auto",
    paddingTop: 50
  },
  section: {
    margin: 20
  },
  description: {
    textAlign: "left",
    marginTop: 20,
    fontWeight: "bold"
  },
  paragraph: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: 600
  },
  notFound: {
    marginTop: 30,
    textAlign: "center",
    fontSize: 14,
    fontWeight: 600
  },
  content: {
    margin: "20px auto",
    background: "rgb(255, 255, 255)",
    borderRadius: "0.25rem",
    boxShadow: "rgb(218 218 218) 1px 1px 0px 1px",
    padding: 20
  },
  input: {
    width: "100%",
    outline: "none",
    padding: "0.75rem",
    fontSize: 14,
    lineHeight: 20,
    height: 40
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 30
  }
};

const ActionEmail = () => {
  const [loader, setLoader] = useState(false);
  const [actionCode, setActionCode] = useState("");

  const onSubmit = async form => {
    setLoader(true);
    const getUserInfoResponse = await sendResetPassword(
      actionCode,
      form.newPassword
    );

    if (!getUserInfoResponse.status) {
      toastrMessage("error", getUserInfoResponse.message);
      setLoader(false);
      return;
    }

    toastrMessage("success", "Sua senha foi alterada com sucesso.");
    setLoader(false);
  };

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    if (Object.keys(parsed).length === 0) {
      window.location.href = "/";
      return;
    }
    setActionCode(parsed.oobCode);
  }, []);

  return (
    <main style={styles.main}>
      <section style={styles.section}>
        <img src={LOGO} alt="" width="150" />
        <h1 className="h3 mb-3" style={styles.description}>
          Olá! <br />Por favor, crie uma senha.
        </h1>
        <div style={styles.content}>
          <FormPassword onSubmit={onSubmit} />
          {loader && <Loader />}
        </div>
      </section>
    </main>
  );
};

export default ActionEmail;
