import React, { useState } from "react";
import { Container } from "./styles";

const InputPassword = ({ placeholder, name, required = true, onChange }) => {
  const [hide, setHide] = useState(false);

  return (
    <Container>
      <input
        type={hide ? "text" : "password"}
        name={name}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
      />
      <i
        className={hide ? "fa fa-eye" : "fa fa-eye-slash"}
        onClick={() => setHide(!hide)}
      />
    </Container>
  );
};

export default InputPassword;
