import {
  getAuth,
  verifyPasswordResetCode,
  confirmPasswordReset
} from "firebase/auth";

import { translateMessage } from "../../utils/Translator";

/**
 * Function Recover Password
 * @param {string} newPassword
 */
export async function sendResetPassword(actionCode, newPassword) {
  try {
    const auth = getAuth();
    await verifyPasswordResetCode(auth, actionCode);
    await confirmPasswordReset(auth, actionCode, newPassword);
    console.log("[sendResetPassword-response]:", true);
    return {
      status: true
    };
  } catch (error) {
    console.log("[sendResetPassword-error]: ", error.message);
    return {
      status: false,
      message: translateMessage(error.message)
    };
  }
}
